/* Inspecting the CF logo on the website: */
/* #f48120 darkorange */
/* #faad3f sandy brown */

/* From the CF style guide */
/* sunriseLightning : linear-gradient(left, #F69259, #FFDB6F) */

@import url(http://fonts.googleapis.com/css?family=Inconsolata);
@import url(http://fonts.googleapis.com/css?family=PT+Sans);
@import url(http://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700);

html,
#text {
  font-family: "PT Sans", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.appWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #f69259, #ffdb6f);
}

.postWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 50vw;
  gap: 10px;
}
.postMetadata {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 700px;
  font-weight: lighter;
  font-size: small;
}

.postTextContent {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 700px;
  margin: 10px;
  font-size: larger;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: groove;
  border-width: 2px;
  border-color: #a08f65;
  background-color: #fff9ec;
  width: 700px;
  padding: 10px;
  margin: 10px;
  gap: 5px;
}

.newPostForm {
  border-style: groove;
  padding: 10px;
  border-width: 2px;
  border-color: #a08f65;
  background-color: #fff9ec;
  width: 900px;
}

.reactionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 700px;
  margin: 10px;
}

.imageWrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

img {
  max-height: 500px;
  max-width: 500px;
  height: auto;
  width: auto;
}
